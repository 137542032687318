<template>
  <div class="bottom">
		<!-- <div class="line" :style="{color: isHome ? '#ffffff' : color }"></div> -->
		<!-- 普通流程 -->
		<template v-if="mode == 0">
			<template v-if="isHome">
				<div class="foot-container" style="color: #000">
					<!-- 英文页脚 -->
					<template v-if="language === 'en'">
            <!-- 
						<div class="text">咨询热线/hotline: +86 400.873.9129</div>
						<div class="text">2025.3.16-19 (4 Days)</div>
						<div class="text">深圳国际会展中心 (宝安新馆)</div>
						<div class="text en-font">Shenzhen World Exhibition & Convention Center</div>
            -->
						<template v-if="isCamp">
						<!-- <div class="text">Hotline: +86 18571622327</div> -->
						<div class="text">H5/7 March 16-19, 2025 (4 Days)</div>
						<div class="text en-font">Shenzhen World Exhibition & Convention Center</div>
						</template>
						<template v-else>
						<!-- <div class="text">hotline: +86 400.873.9129</div> -->
						<div class="text">March 16-19, 2025 (4 Days)</div>
						<div class="text en-font">Shenzhen World Exhibition & Convention Center</div>
						</template>
					</template>
					<!-- 中文页脚 -->
					<template v-else>
						<template v-if="isCamp">
						<!-- <div class="text">咨询热线：18571622327</div> -->
						<div class="text" style="color: white;">H5/7馆 2025.3.16-19（4天）</div>
						<div class="text" style="color: white;">深圳国际会展中心</div>
						</template>
						<template v-else>
						<!-- <div class="text">咨询热线/hotline: 400-873-9129</div> -->
						<div class="text" style="color: white;">2025.3.16-19 (4 Days)</div>
						<div class="text" style="color: white;">深圳国际会展中心 (宝安新馆)</div>
						<div class="text en-font" style="color: white;">Shenzhen World Exhibition & Convention Center</div>
						</template>
					</template>
				</div>
			</template>

			<!-- 次页 -->
			<template v-else>
				<div class="foot-container" :style="{color: color}">
					<!-- 英文页脚 -->
					<template v-if="language === 'en'">
						<template v-if="isCamp">
						<!-- <div class="text">Hotline: +86 18571622327</div> -->
						<div class="text">H5/7 March 16-19, 2025 (4 Days)</div>
						<div class="text en-font">Shenzhen World Exhibition & Convention Center</div>
						</template>
						<template v-else>
						<!-- <div class="text">HotLine: +86 400.873.9129</div> -->
						<div class="text">March 16-19, 2025 (4 Days)</div>
						<div class="text en-font">Shenzhen World Exhibition & Convention Center</div>
						</template>
								</template>
								
								<!-- 中文页脚 -->
								<template v-else>
						<template v-if="isCamp">
						<!-- <div class="text">咨询热线：18571622327</div> -->
						<div class="text">H5/7馆 2025.3.16-19（4天）</div>
						<div class="text">深圳国际会展中心</div>
						</template>
						<template v-else>
						<!-- <div class="text">咨询热线: 400-873-9129</div> -->
						<div class="text">2025年3月16日 - 19日 4天</div>
						<div class="text">深圳国际会展中心 (宝安新馆)</div>
						</template>
					</template>
				</div>
			</template>
		</template>
		
		<!-- VIP流程(海外通用VIP) -->
		<template v-if="mode == 1">
			<template v-if="isCamp">
				<div class="foot-container">
				<!-- <div class="text">Hotline: +86 18571622327</div> -->
				<div class="text">H5/7 March 16-19, 2025 (4 Days)</div>
				<div class="text en-font">Shenzhen World Exhibition & Convention Center</div>
				</div>
			</template>
			<template v-else>
				<div class="foot-container" :style="{color: isHome ? '#ffffff' : color }">
				<!-- <div class="text">HotLine: +86 400.873.9129</div> -->
				<div class="text">March 16-19, 2025 (4 Days)</div>
				<div class="text en-font">Shenzhen World Exhibition & Convention Center</div>
				</div>
			</template>
		</template>
  </div>
</template>

<script>
  export default {
    name: '',
		props: {
			isHome: { // 是否首页
				type: Boolean,
				default: false
			},
			isCamp: { // 是否露营展
				type: Boolean,
				default: false
			},
			color: { // 文字颜色
				type: String,
				// default: '#060211'
				default: '#fff'
			},
			mode: { // 模式: 0-普通; 1-vip
				type: Number,
				default: 0
			},
			level: { // 层级
				type: Number,
				default: 0
			},
			language: {
				type: String,
				default: 'zh'
			},
		},
    data() {
      return {

      }
    },
    created() {
			
    },
    methods: {

    },
  }
</script>

<style lang="less" scoped>
// PC
@media screen and (min-width: 1024px) {
  .bottom {
  	width: 100%;
  	margin: 40Px auto 20Px;
  	
  	.line {
  		width: 600Px;
  		height: 1Px;
  		border-top: 1Px solid;
  		margin: 0 auto 18Px;
  		// background-color: #9F9D9D;
  	}
  	
  	.foot-container {
  	  // color: #9F9D9D;
  	  width: 100%;
  	  margin: 0 auto ;
  	  // border-top: 1px solid; 
  	  padding: 0 0 calc(10Px + env(safe-area-inset-bottom) / 2);
  	}
  	
  	.foot-container .text {
  	  text-align: center;
  	  font-size: 24Px;
  	  line-height: 40Px;
  	}
  }
}
// 移动
@media screen and (max-width: 1023px) {
  .bottom {
  	width: 100%;
  	margin: 20px auto 10px;
  	
  	.line {
  		width: 300px;
  		height: 1px;
  		border-top: 1px solid;
  		margin: 0 auto 9px;
  		// background-color: #9F9D9D;
  	}
  	
  	.foot-container {
  	  // color: #9F9D9D;
  	  width: 100%;
  	  margin: 0 auto ;
  	  // border-top: 1px solid; 
  	  padding: 0 0 calc(5px + env(safe-area-inset-bottom) / 2);
  	}
  	
  	.foot-container .text {
  	  text-align: center;
  	  font-size: 12px;
  	  line-height: 20px;
  	}
  }
}  

</style>
